div.banner {
    position: relative;
}

div.announcement {
    background-color: #ffd452;
    height: 40px;
    width: 100%;
    line-height: 28px;
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    color: black;
}

@font-face {
    font-family: "jackpotFont";
    src: url("/fonts/SHOWG.TTF");
}

.banner .carousel-control.left,
.banner .carousel-control.right {
    background-image: initial;
}

.banner .fa-stack.fa-lg {
    position: absolute;
    top: 40%;
    z-index: 5;
    font-size: 48px;
}

.banner a.left.carousel-control span {
    left: 10%;
}

.banner a.right.carousel-control span {
    right: 10%;
}

.carousel-control-button {
    position: absolute;
    top: 40%;
    z-index: 5;
    display: block;
    background-image: url("/images/slide-icons.png");
    background-size: 85px 373px;
    width: 85px;
    height: 85px;
}

.carousel-control-button.left {
    background-position: 0px -96px;
}

.carousel-control-button.right {
    background-position: 0px 0px;
}

#banner-carousel div.carousel-inner img {
    width: 100%;
}

div.announcement {
    position: relative;
}

div.announcement-header {
    height: 37px;
    position: relative;
}

div.announcement-header span {
    background-image: url("/images/announcement-header-menu.png");
    background-repeat: no-repeat;
    background-size: 35px 25px;
    height: 37px;
    width: 37px;
    display: inline-block;
    margin-top: 7px;
    margin-left: 35px;
    float: left;
}

div.announcement-header p {
    margin-top: 3px;
    margin-left: -10px;
    float: left;
}

div.announcement div.marquee {
    position: absolute;
    top: 3px;
    left: 200px;
    right: 0;
    overflow: hidden;
    color: black;
}

div.announcement div.marquee ul li {
    list-style: none;
    display: inline-block;
}

div.indexPageContent-jackpot {
    height: 250px;
    background: url("/images/jackpot.png") no-repeat;
    background-position: center center;
    position: relative;
}

div.indexPageContent-remarks {
    text-align: center;
}

div.indexPageContent-remarks h3 {
    color: black;
}

div.indexPageContent-remarks p {
    margin: 0 80px;
}

div.indexPageContent-jackpot p {
    position: absolute;
    font-family: "jackpotFont";
    font-size: 63px;
    left: 350px;
    top: 107px;
    background: transparent;
    color: red;
    background: -webkit-linear-gradient(#fd5669, #ef0404);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px #ffbd4a;
    pointer-events: none;
}

section.thumbnailSection div.thumbnail {
    cursor: pointer;
    border: none;
}

section.thumbnailSection div.thumbnail img {
    width: 100%;
}

section.thumbnailSection div.thumbnail .caption * {
    text-align: center;
}
